export enum MixpanelTypes {
    REGISTER = "register",
    LOGIN = "login",
    RESET = "reset",
    PROFILE = "profile",
    EMAIL_VERIFICATION = "email-verification",
    QUEST_START = "quest-start",
    QUEST_SECOND = "quest-two",
    QUEST_THREE = "quest-three",
    QUEST_FINISH = "quest-finish",
    SUMSUB = "sumsub",
}
