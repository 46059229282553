import mixpanel from "mixpanel-browser";
import { MixpanelTypes } from "./types";

export const mixpanelVariant = (
    type: MixpanelTypes,
    id?: number,
    country?: string,
) => {
    switch (type) {
        case MixpanelTypes.REGISTER:
            mixpanel.identify(`${id}`);
            mixpanel.people.set({
                "Country of residence": country,
            });
            mixpanel.track(
                "Register",
                {
                    Source: "Client area form",
                    Platform: "Web",
                }
            );
            return;
        case MixpanelTypes.LOGIN:
            mixpanel.identify(`${id}`);
            mixpanel.people.set({
                "Country of residence": country,
            });
            mixpanel.track(
                "Login",
                {
                    Platform: "Web",
                }
            );
            return;
        case MixpanelTypes.PROFILE:
            mixpanel.track(
                "Onboarding progress viewed",
                {
                    Platform: "Web",
                }
            );
            return ;
        case MixpanelTypes.EMAIL_VERIFICATION:
            mixpanel.track(
                "Email verification started",
                {
                    Platform: "Web",
                }
            );
            return;
        case MixpanelTypes.QUEST_START:
            mixpanel.track(
                "Questionnaire started",
                {
                    Platform: "Web",
                }
            );
            return;
        case MixpanelTypes.QUEST_SECOND:
            mixpanel.track(
                "Questionnaire block completed",
                {
                    Platform: "Web",
                    "Block name": "Personal and financial details",
                }
            );
            return;
        case MixpanelTypes.QUEST_THREE:
            mixpanel.track(
                "Questionnaire block completed",
                {
                    Platform: "Web",
                    "Block name": "Knowledge and experience",
                }
            );
            return;
        case MixpanelTypes.QUEST_FINISH:
            mixpanel.track(
                "Questionnaire block completed",
                {
                    Platform: "Web",
                    "Block name": "Goals with Amega",
                }
            );
            return;
        case MixpanelTypes.SUMSUB:
            mixpanel.track(
                "Documents verification started",
                {
                    Platform: "Web",
                }
            );
            return;
        case MixpanelTypes.RESET:
            return mixpanel.reset();
    }
};
