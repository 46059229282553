import { signOut, getSession } from "next-auth/react";
import posthog from "posthog-js";
import { MixpanelTypes } from "helpers/mixpanel/types";
import { mixpanelVariant } from "helpers/mixpanel";
import { i18n } from "next-i18next";
import { i18n as i18nConfig } from "../next-i18next.config";
import { removeNotificationsWTN } from "./wtn/notifications";

export const statusTokenSignOut = async (status: number) => {
    const { defaultLocale } = i18nConfig || {};
    const { language: locale } = i18n || {};
    const session = await getSession();

    const callbackUrl = (defaultLocale && locale && defaultLocale !== locale) ? `/${locale}/login` : "/login";

    if(status === 401) {
        const id = session ? `${session?.user.client.id}` : "0";
        posthog.reset();
        mixpanelVariant(MixpanelTypes.RESET);
        removeNotificationsWTN(id);
        await signOut({ callbackUrl });
    }
};
